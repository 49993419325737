import React from "react";

import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

const Pricing = () => {
  const smsPricingData = [
    {
      id: 1,
      sms_count: 5000,
      title: "5000 SMS",
      price: "0.25",
      amount: "1250",
      amount_with_tax: "1475",
      btn: "Buy Now",
    },
    {
      id: 2,
      sms_count: 10000,
      title: "10000 SMS",
      price: "0.22",
      amount: "2200",
      amount_with_tax: "2596",
      btn: "Buy Now",
    },
    {
      id: 3,
      sms_count: 25000,
      title: "25000 SMS",
      price: "0.21",
      amount: "5250",
      amount_with_tax: "6195",
      btn: "Buy Now",
    },
    {
      id: 4,
      sms_count: 50000,
      title: "50000 SMS",
      price: "0.18",
      amount: "9000",
      amount_with_tax: "10620",
      btn: "Buy Now",
    },
    {
      id: 5,
      sms_count: 100000,
      title: "1 LAKH SMS",
      price: "0.16",
      amount: "16000",
      amount_with_tax: "18880",
      btn: "Buy Now",
    },
    {
      id: 6,
      sms_count: 500000,
      title: "5 LAKH SMS",
      price: "0.155",
      amount: "77500",
      amount_with_tax: "91450",
      btn: "Buy Now",
    },
    {
      id: 7,
      sms_count: 1000000,
      title: "10 LAKH SMS",
      price: "0.15",
      amount: "150000",
      amount_with_tax: "177000",
      btn: "Buy Now",
    },
  ];

  return (
    <>
      <Header />

      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content w-530">
                <span className="starting__price">Pricing </span>
                <h1 className="banner-title">Pricing</h1>
                <p className="slogan">
                  Affordable Pricing, Maximum Value – Choose What Fits You
                </p>
              </div>
              <div className="rts-hosting-banner__image">
                <img src="/images/pricing.png" alt="" width={550} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        {/* PRICING PLAN START */}
        <div className="rts-pricing-plan pricing-comparison alice__blue section__padding">
          <div className="container">
            <div class="row justify-content-center">
              <div class="rts-section text-center">
                <h3
                  class="rts-section__title"
                  //   data-sal="slide-down"
                  //   data-sal-delay="100"
                  //   data-sal-duration="800"
                >
                  Simple & Flexible Pricing Plans <br /> for all your
                  requirements
                </h3>
                <p
                  class="rts-section__description"
                  //   data-sal="slide-down"
                  //   data-sal-delay="200"
                  //   data-sal-duration="800"
                >
                  No Setup Charges | No Monthly Fees | No Extra Charges for
                  Sender ID | Free API Integration
                </p>
              </div>
            </div>
            <div className="row">
              {/* PRICING PLAN */}
              <div className="col-lg-12">
                <div className="tab__content  open" id="monthly">
                  <table className="table table-hover table-responsive">
                    <thead className="heading__bg">
                      <tr>
                        <th className="cell">NUMBER OF SMS</th>
                        <th className="cell">PER SMS PRICE</th>
                        <th className="cell">AMOUNT</th>
                        <th className="cell">AMOUNT WITH TAX</th>
                        <th className="cell" />
                      </tr>
                    </thead>
                    <tbody className="table__content">
                      {smsPricingData.map((e, index) => {
                        return (
                          <tr key={index}>
                            <td className="package">{e.title}</td>
                            <td className="process">{e.price} INR </td>
                            <td className="ram">₹{e.amount}</td>
                            <td className="storage">₹{e.amount_with_tax}</td>
                            <td>
                              <div
                                className="btn__two primary__btn"
                                onClick={() => {
                                  window.open(
                                    "https://app.formax.cloud/#/login",
                                    "_blank"
                                  );
                                }}
                              >
                                Buy Now
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <p className="pt--25">
                * SMS prices include 2.5 paisa per SMS DLT scrubbing charges.
              </p>
            </div>
          </div>
        </div>
        {/* PRICING PLAN START END */}
      </>

      <Footer />
    </>
  );
};

export default Pricing;

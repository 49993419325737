import React, { useRef } from "react";
import Swal from "sweetalert2";

import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

import PaymentService from "../../Services/PaymentService";
import HttpError from "../../utill/HttpError";

const OnlinePayment = () => {
  const nameRef = useRef();
  const emailRef = useRef();
  const mobileRef = useRef();
  const amountRef = useRef();
  const remarkRef = useRef();

  const paymentHandler = async () => {
    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const mobile = mobileRef.current.value;
    const amount = amountRef.current.value;
    const remark = remarkRef.current.value;

    if (!name) {
      Swal.fire("Opps!", "Please Enter Name", "error");
      return;
    }

    if (!email) {
      Swal.fire("Opps!", "Please Enter Email ID", "error");
      return;
    }

    if (!mobile) {
      Swal.fire("Opps!", "Please Enter Mobile Number", "error");
      return;
    }

    if (mobile.length < 10 || mobile.length > 10) {
      Swal.fire("Opps!", "Invalid Mobile Number", "error");
      return;
    }

    if (!amount) {
      Swal.fire("Opps!", "Please Enter Amount", "error");
      return;
    }

    if (amount > 100000) {
      Swal.fire(
        "Opps!",
        "Amount should be between Rs. 100 - Rs. 100,000",
        "error"
      );
      return;
    }

    try {
      const responseData = await PaymentService.onlinePayment(
        name,
        email,
        mobile,
        amount,
        remark
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const payment_link = responseData.data.payment_link;

        window.open(payment_link, "_blank");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
  };
  return (
    <>
      <Header />

      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content w-530">
                <span className="starting__price">Online Payment </span>
                <h1 className="banner-title">Online Payment</h1>
                <p className="slogan"></p>
              </div>
              <div className="rts-hosting-banner__image">
                <img src="/images/privacy-policy.png" alt="" width={550} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="rts-knowledgebase pt--120">
        <div class="container">
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade active show knowledge__content"
              id="share-hosting-tab"
              role="tabpanel"
              aria-labelledby="share-hosting"
            >
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <h3 className="text-center mb-5">Online Payment</h3>
                  <div class="mb-4 row">
                    <label class="col-sm-3 col-form-label">Full Name</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" ref={nameRef} />
                    </div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-3 col-form-label">Email ID</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" ref={emailRef} />
                    </div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-3 col-form-label">Mobile Number</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" ref={mobileRef} />
                    </div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-3 col-form-label">Amount</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" ref={amountRef} />
                    </div>
                  </div>
                  <div class="mb-5 row">
                    <label class="col-sm-3 col-form-label">Remark</label>
                    <div class="col-sm-9">
                      <textarea class="form-control" rows="3" ref={remarkRef} />
                    </div>
                  </div>

                  <div className="text-center">
                    <a class="rts-btn rts-btn-primary" onClick={paymentHandler}>
                      Make Payment
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OnlinePayment;

import common from "../utill/Common";

class PaymentService {
  onlinePayment = async (name, email, mobile, amount, remark) => {
    const url = common.baseUrl + "payment/online_payment";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, mobile, amount, remark }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

export default new PaymentService();

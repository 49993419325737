import React from "react";

const OurPricing = () => {
  return (
    <>
      {/* PRICING PLAN */}
      <div className="rts-pricing-plan  section__padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="rts-section text-center">
              <h3
                className="rts-section__title"
                // data-sal="slide-down"
                // data-sal-delay={100}
                // data-sal-duration={800}
              >
                Choose SMS Plan
              </h3>
              <p
                className="rts-section__description"
                // data-sal="slide-down"
                // data-sal-delay={300}
                // data-sal-duration={800}
              >
                Affordable Pricing, Maximum Value – Choose What Fits You
              </p>
            </div>
          </div>
          <div className="row">
            <div
              className="row justify-content-center"
              //   data-sal="slide-down"
              //   data-sal-delay={500}
              //   data-sal-duration={800}
            >
              <div className="col-lg-7 col-md-7">
                <div className="rts-pricing-plan__tab color-primary">
                  <div className="tab__button">
                    <div className="tab__button__item">
                      <button className="tab__btn active" data-tab="monthly">
                        Transactional SMS
                      </button>
                      <button className="tab__btn" data-tab="monthly">
                        Promotional SMS
                      </button>
                      <button className="tab__btn" data-tab="monthly">
                        OTP SMS
                      </button>
                    </div>
                  </div>
                  <p className="pt--20" style={{ textAlign: "justify" }}>
                    Note: Our pricing remains consistent across all SMS
                    types—whether it's OTP SMS, Transactional SMS, or
                    Promotional SMS. Enjoy transparent and straightforward rates
                    for every type of message service we offer.
                  </p>
                </div>
              </div>
            </div>
            {/* PRICING PLAN */}
            <div className="tab__content open" id="monthly">
              <div className="row g-30 monthly">
                {/* single card */}
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="card-plan style-with-bg">
                    <div className="card-plan__content">
                      <div className="card-plan__package">
                        {/* <div className="icon">
                          <img
                            src="assets/images/pricing/basic.svg"
                            height={30}
                            width={30}
                            alt=""
                          />
                        </div> */}
                        <h4 className="package__name">5000 SMS</h4>
                      </div>
                      <p className="card-plan__desc"></p>
                      <h5 className="card-plan__price">
                        <sup>₹</sup> 1250<sub></sub>
                      </h5>
                      <div className="card-plan__cartbtn">
                        <a
                          href="#"
                          onClick={() => {
                            window.open(
                              "https://app.formax.cloud/#/login",
                              "_blank"
                            );
                          }}
                        >
                          Buy Now
                        </a>
                      </div>
                      <p className="card-plan__renew-price">
                        18% GST applicable
                      </p>
                      <div className="card-plan__feature">
                        <ul className="card-plan__feature--list">
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 5000 SMS
                              Credit
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 0.25 INR Per
                              SMS
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Lifetime
                              Validity
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Developer API
                              Access
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> DLT Scrubbing
                              Charges Includes
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> DLT
                              Registration Support
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Realtime
                              Delivery Reports
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Easy to use
                              Web-Panel
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 24/7 Dedicated
                              Support
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* single card end */}
                {/* single card */}
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="card-plan style-with-bg">
                    <div className="card-plan__content">
                      <div className="card-plan__package">
                        {/* <div className="icon">
                          <img
                            src="assets/images/pricing/basic.svg"
                            height={30}
                            width={30}
                            alt=""
                          />
                        </div> */}
                        <h4 className="package__name">10000 SMS</h4>
                      </div>
                      <p className="card-plan__desc"></p>
                      <h5 className="card-plan__price">
                        <sup>₹</sup> 2200<sub></sub>
                      </h5>
                      <div className="card-plan__cartbtn">
                        <a
                          href="#"
                          onClick={() => {
                            window.open(
                              "https://app.formax.cloud/#/login",
                              "_blank"
                            );
                          }}
                        >
                          Buy Now
                        </a>
                      </div>
                      <p className="card-plan__renew-price">
                        18% GST applicable
                      </p>
                      <div className="card-plan__feature">
                        <ul className="card-plan__feature--list">
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 10000 SMS
                              Credit
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 0.22 INR Per
                              SMS
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Lifetime
                              Validity
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Developer API
                              Access
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> DLT Scrubbing
                              Charges Includes
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> DLT
                              Registration Support
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Realtime
                              Delivery Reports
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Easy to use
                              Web-Panel
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 24/7 Dedicated
                              Support
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* single card end */}
                {/* single card */}
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="card-plan style-with-bg">
                    <div className="card-plan__content">
                      <div className="card-plan__package">
                        {/* <div className="icon">
                          <img
                            src="assets/images/pricing/basic.svg"
                            height={30}
                            width={30}
                            alt=""
                          />
                        </div> */}
                        <h4 className="package__name">25000 SMS</h4>
                      </div>
                      <p className="card-plan__desc"></p>
                      <h5 className="card-plan__price">
                        <sup>₹</sup> 5250<sub></sub>
                      </h5>
                      <div className="card-plan__cartbtn">
                        <a
                          href="#"
                          onClick={() => {
                            window.open(
                              "https://app.formax.cloud/#/login",
                              "_blank"
                            );
                          }}
                        >
                          Buy Now
                        </a>
                      </div>
                      <p className="card-plan__renew-price">
                        18% GST applicable
                      </p>
                      <div className="card-plan__feature">
                        <ul className="card-plan__feature--list">
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 25000 SMS
                              Credit
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 0.21 INR Per
                              SMS
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Lifetime
                              Validity
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Developer API
                              Access
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> DLT Scrubbing
                              Charges Includes
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> DLT
                              Registration Support
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Realtime
                              Delivery Reports
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Easy to use
                              Web-Panel
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 24/7 Dedicated
                              Support
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* single card end */}
                {/* single card */}
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="card-plan style-with-bg">
                    <div className="card-plan__content">
                      <div className="card-plan__package">
                        {/* <div className="icon">
                          <img
                            src="assets/images/pricing/basic.svg"
                            height={30}
                            width={30}
                            alt=""
                          />
                        </div> */}
                        <h4 className="package__name">50000 SMS</h4>
                      </div>
                      <p className="card-plan__desc"></p>
                      <h5 className="card-plan__price">
                        <sup>₹</sup> 9000<sub></sub>
                      </h5>
                      <div className="card-plan__cartbtn">
                        <a
                          href="#"
                          onClick={() => {
                            window.open(
                              "https://app.formax.cloud/#/login",
                              "_blank"
                            );
                          }}
                        >
                          Buy Now
                        </a>
                      </div>
                      <p className="card-plan__renew-price">
                        18% GST applicable
                      </p>
                      <div className="card-plan__feature">
                        <ul className="card-plan__feature--list">
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 50000 SMS
                              Credit
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 0.18 INR Per
                              SMS
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Lifetime
                              Validity
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Developer API
                              Access
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> DLT Scrubbing
                              Charges Includes
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> DLT
                              Registration Support
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Realtime
                              Delivery Reports
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Easy to use
                              Web-Panel
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 24/7 Dedicated
                              Support
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* single card end */}
              </div>
              {/* ROW */}
              <div className="row g-30 monthly pt--30 justify-content-center">
                {/* single card */}
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="card-plan style-with-bg">
                    <div className="card-plan__content">
                      <div className="card-plan__package">
                        {/* <div className="icon">
                          <img
                            src="assets/images/pricing/basic.svg"
                            height={30}
                            width={30}
                            alt=""
                          />
                        </div> */}
                        <h4 className="package__name">1,00,000 SMS</h4>
                      </div>
                      <p className="card-plan__desc"></p>
                      <h5 className="card-plan__price">
                        <sup>₹</sup> 16000<sub></sub>
                      </h5>
                      <div className="card-plan__cartbtn">
                        <a
                          href="#"
                          onClick={() => {
                            window.open(
                              "https://app.formax.cloud/#/login",
                              "_blank"
                            );
                          }}
                        >
                          Buy Now
                        </a>
                      </div>
                      <p className="card-plan__renew-price">
                        18% GST applicable
                      </p>
                      <div className="card-plan__feature">
                        <ul className="card-plan__feature--list">
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 1,00,000 SMS
                              Credit
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 0.16 INR Per
                              SMS
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Lifetime
                              Validity
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Developer API
                              Access
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> DLT Scrubbing
                              Charges Includes
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> DLT
                              Registration Support
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Realtime
                              Delivery Reports
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Easy to use
                              Web-Panel
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 24/7 Dedicated
                              Support
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* single card end */}
                {/* single card */}
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="card-plan style-with-bg">
                    <div className="card-plan__content">
                      <div className="card-plan__package">
                        {/* <div className="icon">
                          <img
                            src="assets/images/pricing/basic.svg"
                            height={30}
                            width={30}
                            alt=""
                          />
                        </div> */}
                        <h4 className="package__name">5,00,000 SMS</h4>
                      </div>
                      <p className="card-plan__desc"></p>
                      <h5 className="card-plan__price">
                        <sup>₹</sup> 77500<sub></sub>
                      </h5>
                      <div className="card-plan__cartbtn">
                        <a
                          href="#"
                          onClick={() => {
                            window.open(
                              "https://app.formax.cloud/#/login",
                              "_blank"
                            );
                          }}
                        >
                          Buy Now
                        </a>
                      </div>
                      <p className="card-plan__renew-price">
                        18% GST applicable
                      </p>
                      <div className="card-plan__feature">
                        <ul className="card-plan__feature--list">
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 5,00,000 SMS
                              Credit
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 0.155 INR Per
                              SMS
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Lifetime
                              Validity
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Developer API
                              Access
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> DLT Scrubbing
                              Charges Includes
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> DLT
                              Registration Support
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Realtime
                              Delivery Reports
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Easy to use
                              Web-Panel
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 24/7 Dedicated
                              Support
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* single card end */}
                {/* single card */}
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="card-plan style-with-bg">
                    <div className="card-plan__content">
                      <div className="card-plan__package">
                        {/* <div className="icon">
                          <img
                            src="assets/images/pricing/basic.svg"
                            height={30}
                            width={30}
                            alt=""
                          />
                        </div> */}
                        <h4 className="package__name">10 LAKH SMS</h4>
                      </div>
                      <p className="card-plan__desc"></p>
                      <h5 className="card-plan__price">
                        <sup>₹</sup> 150000<sub></sub>
                      </h5>
                      <div className="card-plan__cartbtn">
                        <a
                          href="#"
                          onClick={() => {
                            window.open(
                              "https://app.formax.cloud/#/login",
                              "_blank"
                            );
                          }}
                        >
                          Buy Now
                        </a>
                      </div>
                      <p className="card-plan__renew-price">
                        18% GST applicable
                      </p>
                      <div className="card-plan__feature">
                        <ul className="card-plan__feature--list">
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 10 LAKH SMS
                              Credit
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 0.15 INR Per
                              SMS
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Lifetime
                              Validity
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Developer API
                              Access
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> DLT Scrubbing
                              Charges Includes
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> DLT
                              Registration Support
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Realtime
                              Delivery Reports
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> Easy to use
                              Web-Panel
                            </span>
                          </li>
                          <li className="card-plan__feature--list-item">
                            <span className="text">
                              <i className="fa-light fa-check" /> 24/7 Dedicated
                              Support
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* single card end */}
              </div>
            </div>
            {/* PRICING PLAN */}
            <div className="tab__content" id="yearly"></div>
          </div>
        </div>
      </div>
      {/* PRICING PLAN END */}
    </>
  );
};

export default OurPricing;
